<template>

    <v-container fluid class="d-flex justify-end align-items-center">
        <div class="d-flex align-center">Rows per page: {{ data.length }}</div>
        <div class="ml-5 d-flex align-center">{{ itemsSpan }}</div>
        <div class="ml-5">
            <v-btn icon :disabled="data.links.previous === null" @click="paginate(data.links.previous)"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-btn icon :disabled="data.links.next === null" @click="paginate(data.links.next)"><v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
    </v-container>

</template>

<script>
import { itemsSpan } from '../../helpers/index'
export default {
    props:['data', 'paginate'],
    computed: {
        itemsSpan
    }
}
</script>

<style>

</style>