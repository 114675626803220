<template>

<v-container fluid>
  <v-data-table
    :headers="headers"
    :items="data.results"
    :loading="loading"
    hide-default-footer
    :class="{'elevation-1': true, 'row-pointer': options.rowClick}"
    @click:row="handleClick"
    >
    <!--Header-->
    <template v-slot:top>
        <slot></slot>
    </template>

    <!--Check for every property, if no data show message insted of blank cell-->
    <template v-for="(itemKey, index) in itemKeys" v-slot:[`item.${itemKey}`]="{ item }">
        <div :key="index">{{ item[itemKey] == null ? 'no data' : item[itemKey] }}</div>
    </template>

    <!--Show user images if available in as avatars -->
    <template v-for="(avatar, index) in options.avatar" v-slot:[`item.${avatar}`]="{ item }">
        <v-avatar :key="index">
            <img
                :src="item[avatar]"
            >
        </v-avatar>
    </template>

    <!--Convert every timestamp into human readable format-->
    <template v-for="(timestamp, index) in options.timestamps" v-slot:[`item.${timestamp}`]="{ item }">
        <div v-if="item[timestamp] == null" :key="index">No data</div>
        <div v-else :key="index">{{  moment(item[timestamp]).format("YYYY-MM-DD HH:mm:ss")  }}</div>
    </template>

    <!--CTA icon buttons-->
    <template v-slot:[`item.actions`]="{ item }">
        <v-btn
        v-for="(action, index) in options.actions"
        :key="index"
        :color="action.color"
        dark
        rounded
        small
        icon
        :href="item[action.hrefName]"
        target="_blank"
        class="mr-1"
        >
            <v-icon>
                {{ action.icon }}
            </v-icon>
        </v-btn>
    </template>

    <!--Status chips-->
    <template v-for="(statusChip, index) in options.statusChips" v-slot:[`item.${statusChip}`]="{ item }">
        <v-chip
        :key="index"
        :color="getColor(item[statusChip])"
        dark
        class="status-chip status-pill rounded-pill white--text font-weight-black"
        medium
        >
        <div class="d-flex justify-center">
            {{ item[statusChip] }}
        </div>
        </v-chip>
    </template>

    <!--Content grade chip-->
    <template v-for="(contentGradeChip, index) in options.contentGradeChips" v-slot:[`item.${contentGradeChip}`]="{ item }">
        <v-chip
        :key="index"
        :color="getContentGradeColor(item[contentGradeChip])"
        :text-color="getTextContentGradeColor(item[contentGradeChip])"
        dark
        medium
        >
        <div class="d-flex justify-center" style="min-width: 70px;">
            {{ item[contentGradeChip] }} / 10
        </div>
        </v-chip>
    </template>

    </v-data-table>

    <!--Footer-->
    <DataTableFooter 
    v-if="paginate"
    :data="data"
    :paginate="paginate"
    />
</v-container>

</template>

<script>
import DataTableFooter from '../common/DataTableFooter.vue'
import { getColor, getContentGradeColor, getTextContentGradeColor, initialize } from '../../helpers/index'
export default {
    data(){
        return {
            testing: [
                'nickname'
            ]
        }
    },
    components: {
        DataTableFooter,
    },
    props:{
        headers: { type: Array },
        data: { type: Object },
        loading: { type: Boolean },
        pageNumber: { type: Number },
        paginate: { type: Function },
        options: { type: Object, default: () => { return {}; } }
    },
    computed:{
        itemKeys(){
            if(this.data.results.length === 0) return [];

            return Object.keys(this.data.results[0]);
        }
    },
    methods: {
        getColor,
        getContentGradeColor,
        getTextContentGradeColor,
        initialize,
        handleClick(row){
            if(this.options.rowClick){
                this.options.rowClick(row)
            }
        }
    },
    created(){
        this.initialize()
    },
}
</script>

<style scoped>
.status-chip{
    display: grid;
    justify-content: center;
}
.breadcrumb-link{
    color: #0000008a !important;
}
.status-pill {
    width: 110px;
    text-align: center;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>