<template>
  <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                :href="item.href"
            >
                <a class="breadcrumb-link">{{ item.text.toUpperCase() }}</a>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    props: ['breadcrumbs']
}
</script>

<style scoped>
.status-chip{
  display: grid;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  width: 120px;
}
.breadcrumb-link{
        color: #0000008a !important;
    }
</style>